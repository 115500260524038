body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

h4 {
  font-weight: bold;
}

/* Modify Navbar */ 
.navbar-custom { 
  background-color: #F7D94C; 
}
.navbar-custom .navbar-brand, .navbar-custom .navbar-text { 
  color: #1B2AB3;
  font-size: 200%;
  font-weight: bold;
}

.font1 {
  font-family: 微軟正黑體, "Microsoft JhengHei";
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

@media (max-width: 767px) {
  .hiddenInMobile{ display: none; }
  .layoutMain{
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .hiddenInWeb{ display: none; }
  .layoutMain{
    display: flex;
    flex-direction: row;  
  }
}
