@media screen and (min-width: 768px) {
  .rwdtable thead {
      vertical-align: middle;
  }
  .rwdtable td {
      vertical-align: middle;
      text-align: left;
  }
  .rwdtdbutton {
      text-align: center;
      width: 1px;
  }
  .tableHiddenInWeb {
      display: none;
  }
}

@media screen and (max-width: 767px) {
  .rwdtable {
      border: 0;
  }
  .rwdtable thead {
      overflow: hidden;
      position: absolute;
      width: 0px;
      border-style: none;
  }
  .rwdtable tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 1em;
  }
  .rwdtable td {
      border-bottom: 1px solid #ddd;
      display: block;
      text-align: left;
  }
  .rwdtable td:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
  }
  .rwdtable td:last-child {
      border-bottom: 0px;
  }
  .rwdtdbutton {
      text-align: center;
  }
  .tableHiddenInMobile {
      display: none;
  }
}

/* search bar */
.search {
    float: right;
    position: relative;
    display: flex;
  }
  
  .searchTerm {
    width: 100%;
    border: 1px solid #CCCCCC;
    border-right: none;
    padding: 4px;
    border-radius: 4px 0 0 4px;
    outline: none;
  }
  
  .searchButton {
    width: 40px;
    height: 34px;
    border: 1px solid #CCCCCC;
    background: #CCCCCC;
    text-align: center;
    color: #FFFFFF;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
  }

  .search .form-control:focus {
    border-color: #CCCCCC;
  }